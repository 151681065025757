<template>
  <div>
    <div
      class="header-menu__back button button_empty-pink button_small"
      v-if="parent"
      @click="$emit('back')"
    >
      <span class="mr-2">
        <svg
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6 1L1 6L6 11" stroke="currentColor" stroke-linecap="square" />
        </svg>
      </span>
      Назад
    </div>
    <div class="header-menu__title">{{ parent ? parent.title : "Меню" }}</div>
    <div
      v-for="(item, ind) in items"
      :key="ind"
      class="header-menu__group"
      :class="{ 'header-menu__group_opened': isOpened === item }"
    >
      <div class="header-menu__item">
        <div
          @click="toMenuItem(item.link, item.title)"
          :data-id="item.ref"
          class="header-menu__link"
        >
          {{ item.title }}
        </div>
        <div
          class="header-menu__item-icon"
          @click="toggleItem(item)"
          v-if="item.childrens"
        >
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L6 6L1 11" stroke="#3C4242" stroke-linecap="square" />
          </svg>
        </div>
      </div>

      <div class="header-menu__list" v-if="item.childrens">
        <HeaderMenuBlock
          :level="level + 1"
          :items="item.childrens"
          :parent="item"
          @back="back"
          @menuOpen="$emit('menuOpen', true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenuBlock from "./HeaderMenuBlock";
import { bus } from "@/main";
export default {
  name: "HeaderMenuBlock",
  components: {
    HeaderMenuBlock,
  },
  props: {
    items: Array,
    level: Number,
    parent: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data: () => ({
    isOpened: null,
  }),
  computed: {},
  methods: {
    toMenuItem(to, item) {
       if(!to) return
      bus.$emit("scrollLock", false);
      const vm = this;
      let obj = {};
      if (this.$parent?.parent) {
        obj = {
          first: this.$parent?.parent.title,
          second: this.parent.title,
        };
      } else if (this.parent) {
        obj = {
          first: this.parent.title,
          second: item,
        };
      } else {
        obj = {
          first: item,
          second: "None",
        };
      }
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "header menu click", {
          "header menu click": {
            "menu item": item,
            "first menu level": obj.first,
            "second menu level": obj.second,
            ...this.$root.ymFields
          },
        });
      }
      if (typeof to !== "object") {
        window.open(to, "_blank");
      } else {
        this.$router.push(to).catch(() => {
          this.$emit("closeMenu");
        });
      }
    },
    back() {
      this.$emit("menuOpen", false);
      this.isOpened = null;
    },
    toggleItem(item) {
      this.$emit("menuOpen", true);
      this.isOpened = item;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-menu {
  &__title {
    cursor: pointer;
    margin-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__back {
    margin-bottom: 36px;
    width: 80px;
    height: 28px;
  }

  &__item-icon {
    transition: 0.3s;
    transform: rotate(0);
  }

  &__group {
    &_opened {
      > .header-menu__list {
        left: 0;
      }

      > .header-menu__item .header-menu__item-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__item {
    cursor: pointer;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__link {
    cursor: pointer;
    display: block;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;
  }

  &__list {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: 0.3s;
    padding: 0 24px;
    z-index: 10;
  }
}
</style>